export default function fRegisterForm() {
    const oLocation = document.querySelector('.js-location');
    const oToggleForm = document.querySelector('.js-toggle-date');
    const oOnlineText = document.querySelector('.js-online-text');
    const oDatepicker = oToggleForm.querySelector('#datepicker');

    let picker = new Pikaday({
        field: document.getElementById('datepicker'),
        maxDate: new Date(2018, 9, 31),
        disableWeekends: true,
        format: 'DD MM YYYY',
    });

    oLocation.addEventListener('change', () => {
        let selectedValue = oLocation.value;

        //console.log(selectedValue);

        if (selectedValue === "online") {
            oDatepicker.required = false;
            oToggleForm.classList.remove('show');
            oOnlineText.classList.remove('hide');
        } else {
            oDatepicker.required = true;
            oOnlineText.classList.add('hide');
            oToggleForm.classList.add('show');
        }
    });
}
