﻿export function fInitModal() {
    var aTriggers = document.querySelectorAll('.js-modal-open');

    let oScriptTag = document.createElement('script');
    oScriptTag.src = "https://www.youtube.com/iframe_api";

    let oFirstScriptTag = document.getElementsByTagName('script')[0];
    oFirstScriptTag.parentNode.insertBefore(oScriptTag, oFirstScriptTag);

    function openModal(oTrigger) {
        var sModalId = oTrigger.getAttribute('data-modal-id');
        var oModal = document.getElementById(sModalId);
        var bVideoModal = oTrigger.getAttribute('data-modal-video');

        if (!oModal) return;

        if (bVideoModal) {
            let oVideoFrame = document.createElement('div');
            let sVideoId = oTrigger.getAttribute('data-video-id');

            oVideoFrame.id = sVideoId;
            oModal.appendChild(oVideoFrame);

            // Calculating video modal width and height (ratio of 16:9)
            oModal.style.width = (window.innerWidth > 1200) ? '1200px' : (window.innerWidth * 85) / 100 + 'px';
            oModal.style.height = (((window.innerWidth * 85) / 100) * 56.25) / 100 + 'px';

            let oPlayer = new YT.Player(sVideoId, {
                videoId: sVideoId,
                playerVars: {
                    rel: 0,
                    controls: 0,
                    modestbranding: 1,
                    autoplay: 1,
                    showinfo: 0,
                    start: 0
                }
            });
        }

        // Create modal-background
        var oModalBg = document.createElement('div');
        oModalBg.classList.add('modal-bg');
        document.body.appendChild(oModalBg);

        // Open Modal and bg
        oModal.classList.add('is-active');
        oModalBg.classList.add('is-active');
        document.body.classList.add('no-scroll');

        // Closing the modal
        function fCloseModal() {
            oModalBg.parentNode.removeChild(oModalBg);
            oModal.classList.remove('is-active');
            document.body.classList.remove('no-scroll');

            if (bVideoModal) {
                let oIframe = oModal.querySelector('iframe');

                if (oIframe) oIframe.parentNode.removeChild(oIframe);
            }
        }

        oModalBg.addEventListener('click', fCloseModal);

        var aModalClosers = oModal.querySelectorAll('.js-modal-close');
        for (var j = 0; j < aModalClosers.length; j += 1) {
            aModalClosers[j].addEventListener('click', fCloseModal);
        }
    }

    for (var i = 0; i < aTriggers.length; i += 1) {
        aTriggers[i].addEventListener('click', function () {
            openModal(this);
        });
    }
}
