import $ from 'jquery';
export default function headerDrawer() {

    $('.js-drawer-open').click(function(){
        $('.mobile-nav').addClass('js-is-active');
        $('.header__body').addClass('js-menu-opened');
        $('body').addClass('no-scroll');
    });
    $('.js-drawer-close').click(function(){
        $('.mobile-nav').removeClass('js-is-active');
        $('.header__body').removeClass('js-menu-opened');
        $('body').removeClass('no-scroll');
    });

    $('.js-drawer-open-sub').click(function(e){
        var el = $(this).siblings('.mobile-nav__drawer__sub');
        el.addClass('js-is-active');

        $('.mobile-nav__drawer').addClass('js-sub-open');
        $('.mobile-nav__drawer').scrollTop(0);

        e.stopPropagation();
    });

    $('.js-drawer-close-sub').click(function(e){
        var el = $(this).parents('.mobile-nav__drawer__sub');
        $('.mobile-nav__drawer').removeClass('js-sub-open');
        el.removeClass('js-is-active');

        e.stopPropagation();
    });

}
