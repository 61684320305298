class NewsletterPopup extends HTMLElement {
    get timeout() {
        return parseFloat(this.getAttribute('timeout')) || 30;
    }

    get daysToHide () {
        return parseFloat(this.getAttribute('daystohide')) || 7;

    }
    constructor() {
        super();
        this.$popup = this.querySelector('[data-popup]');
        this.$closeBtn = this.querySelector('[data-close]');
    }

    connectedCallback() {

        const newsletterNextdate = window.localStorage.getItem('newsletter-nextdate');

        // nothing set
        if (!newsletterNextdate) {
            this.go();
            return;
        }

        // not a valid date
        if (Number.isNaN(new Date(newsletterNextdate).getTime())) {
            window.localStorage.removeItem('newsletter-nextdate');
            this.go();
            return;
        }

        if (new Date() > newsletterNextdate) {
            this.go();
        }
    }

    go() {
        setTimeout(() => {
            this.showPopup();
        }, this.timeout * 1000);
    }

    showPopup() {
        this.$closeBtn.addEventListener('click', () => {
            this.closePopup();
        });

        this.$popup.hidden = false;
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                this.$popup.classList.add('is-open');
            })
        })
    }

    closePopup() {
        this.$popup.classList.remove('is-open');
        this.$popup.addEventListener('transitionend', () => {
            this.$popup.hidden = true;
        });

        const now = new Date();
        now.setDate(now.getDate() + this.daysToHide);
        // set timeout to localstorage
        window.localStorage.setItem('newsletter-nextdate', now);
    }
}

window.customElements.define('newsletter-popup', NewsletterPopup);
