import $ from 'jquery';

export default function customerService() {

    $('.js-open-customer-service').click(function(){
          $('.footer__contact').toggleClass('show');
           $('.footer__title__chevron').toggleClass('rotate');
    });

    // $('.js-open-customer-service').click(function(){
    //     $('.footer__contact').addClass('js-show-service');
    //     $('.footer__title__chevron').addClass('js-service-opened');
    // });




}
