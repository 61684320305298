﻿
export default function(el) {
    const videoContainer = el;
    const videoId = encodeURIComponent(videoContainer.getAttribute('data-videoid'));
    const playButton = videoContainer.querySelector('button');

    playButton.addEventListener('click', addIframe.bind(this), { once: true });

    function addIframe() {
        const iframeHTML = `
        <iframe width="560" height="315" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
          src="https://www.youtube-nocookie.com/embed/${videoId}?rel=0&autoplay=1"
        ></iframe>`;

        videoContainer.insertAdjacentHTML('beforeend', iframeHTML);
        videoContainer.classList.add('is-activated');
        playButton.remove();
    }
}
