import $ from './thing';
import 'jquery-validation';

export function emailSubscribeValidation() {
    $("#email-form").validate({
        ignore: [],
        rules: {
           Email: {
               email: true
           }
       },
       onkeyup: false,

       errorPlacement: function(error, element) {
           if ($(element).parent(".footer__form__wrapper").length > 0) {
               if ($(element).parent(".form__custom-checkbox__label").length > 0) {
                   error.insertAfter($(element).parent());
               } else {
                   error.insertAfter($(element).parent(".footer__form__wrapper"));
               }
           } else {
             error.insertAfter(element);
           }
       },

        highlight: function(element, errorClass, validClass){

            const fTest = function () {
              var msg = $(element).next('.error');
              var customErrorClass = errorClass;

              if ($(element).data("warning") && (msg.text() === $(element).data("msg-required"))){
                  customErrorClass = "warning";
              }
              $(element).removeClass(validClass + " " + errorClass).addClass(customErrorClass);

              clearTimeout(fTest);
            };
            setTimeout(fTest, 0);

        },
        unhighlight: function(element, errorClass, validClass){

            var msg = $(element).next('.error');
            var customErrorClass = errorClass;

            if ($(element).data("warning") && (msg.text() !== $(element).data("msg-required"))){
                customErrorClass = "warning";
            }
            $(element).removeClass(customErrorClass).addClass(validClass);

        },
        submitHandler: function (form) {
            let sKey = form.getAttribute('data-sitekey');
            let sCallback = form.getAttribute('data-callback');
            let oButton = form.querySelector('button[type="submit"]');

            oButton.classList.add('is-disabled');

            var widget = grecaptcha.render(oButton, {
                "sitekey" : sKey,
                "callback" : sCallback
            });
            grecaptcha.reset(widget);
            grecaptcha.execute(widget);
        }
   });
}

export function signupValidation() {
    $("#signup-form").validate({
        rules: {
           Email: {
               email: true
           },
           Phone: {
               phoneNL: true
           },
           Postalcode: {
               postalcodeNL: true
           }

       },
       onkeyup: false,
       ignore: ".ignore",

       errorPlacement: function(error, element) {
           if ($(element).parent(".form__custom-checkbox__label").length > 0) {
             $(element).parent().append(error);
           } else {
             error.insertAfter(element);
           }
       },

        highlight: function(element, errorClass, validClass){

            const fTest = function () {
              var msg = $(element).next('.error');
              var customErrorClass = errorClass;

              if ($(element).data("warning") && (msg.text() === $(element).data("msg-required"))){
                  customErrorClass = "warning";
              }
              $(element).removeClass(validClass + " " + errorClass).addClass(customErrorClass);

              clearTimeout(fTest);
            };
            setTimeout(fTest, 0);

        },
        unhighlight: function(element, errorClass, validClass){

            var msg = $(element).next('.error');
            var customErrorClass = errorClass;

            if ($(element).data("warning") && (msg.text() !== $(element).data("msg-required"))){
                customErrorClass = "warning";
            }
            $(element).removeClass(customErrorClass).addClass(validClass);

        }
    });
}

export function questionValidation() {
    $("#question-form").validate({
        rules: {
           Email: {
               email: true
           },
           Phone: {
               phoneNL: true
           }

       },
       onkeyup: false,
       ignore: ".ignore",

        highlight: function(element, errorClass, validClass){

            const fTest = function () {
              var msg = $(element).next('.error');
              var customErrorClass = errorClass;

              if ($(element).data("warning") && (msg.text() === $(element).data("msg-required"))){
                  customErrorClass = "warning";
              }
              $(element).removeClass(validClass + " " + errorClass).addClass(customErrorClass);

              clearTimeout(fTest);
            };
            setTimeout(fTest, 0);

        },
        unhighlight: function(element, errorClass, validClass){

            var msg = $(element).next('.error');
            var customErrorClass = errorClass;

            if ($(element).data("warning") && (msg.text() !== $(element).data("msg-required"))){
                customErrorClass = "warning";
            }
            $(element).removeClass(customErrorClass).addClass(validClass);

        }
    });
}

export function defaultValidation() {
    $("#ContactForm").validate({
        ignore: [],
        submitHandler: function (form) {
            let sKey = form.getAttribute('data-sitekey');
            let sCallback = form.getAttribute('data-callback');
            let oButton = form.querySelector('button[type="submit"]');

            oButton.classList.add('is-disabled');

            var widget = grecaptcha.render(oButton, {
                "sitekey" : sKey,
                "callback" : sCallback
            });
            grecaptcha.reset(widget);
            grecaptcha.execute(widget);
        }
    });
    $("#RegistrationForm").validate({
        ignore: [],
        submitHandler: function (form) {
            let sKey = form.getAttribute('data-sitekey');
            let sCallback = form.getAttribute('data-callback');
            let oButton = form.querySelector('button[type="submit"]');

            oButton.classList.add('is-disabled');

            var widget = grecaptcha.render(oButton, {
                "sitekey" : sKey,
                "callback" : sCallback
            });
            grecaptcha.reset(widget);
            grecaptcha.execute(widget);
        }
    });
}

export function fTogglePaymentMethod() {
    var oForm = document.getElementById('SubscriptionForm');
    var oInput = oForm.querySelector('.js-togglePayment-input');
    var oTarget = oForm.querySelector('.js-togglePayment-target');
    var oTargetInput = oTarget.querySelector('.js-togglePayment-targetInput');

    var oValidator = $(oForm).validate({
        ignore: [],
        submitHandler: function (element, event) {
            if ($('.g-recaptcha').length > 0) {
                event.preventDefault();
                grecaptcha.reset();
                grecaptcha.execute();
            } else {
                element.submit();
            }
        }
    });

    oInput.addEventListener('change', function () {
        if (oInput.options[oInput.selectedIndex].value === 'Acceptgiro') {
            oTarget.style.display = 'none';
            oTargetInput.removeAttribute('required');
        } else {
            oTarget.style.removeProperty('display');
            oTargetInput.setAttribute('required', 'true');
        }

        oValidator.resetForm();
    });
}
