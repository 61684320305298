import $ from 'jquery';

export default function openQuestion() {
    $('.js-toggle-question').click(function(){

        $(this).siblings().slideToggle();
        // $(this).slideToggle('line');
        $(this).children().toggleClass('rotate');

        // $('.question__answer').toggleClass('show');
        // $('.question__item').toggleClass('line');
        // $('.question__icon').toggleClass('rotate');
    });
}
