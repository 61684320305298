import $ from 'jquery';
import headerDrawer from './header-drawer';
import customerService from './customer-service';
import { emailSubscribeValidation, signupValidation, questionValidation, defaultValidation, fTogglePaymentMethod } from './validation';
import openQuestion from './question';
import { initSlider, initHomeSlider } from './quote-slider';
// import fInitCookieBar from './cookie-bar';
import { fInitModal } from './modal';
import fRegisterForm from "./register-form";
import initIsotope from "./benefitsFilter";
import videoblock from "./video";
import './newsletter-popup';

// JavaScript | Main
// 'use strict';

// $(function () {
    backToTop();
    stickyScrollBar();
    headerDrawer();
    // objectFitImages();
    customerService();
    emailSubscribeValidation();
    signupValidation();
    questionValidation();
    defaultValidation();
    openQuestion();
    initSlider();
    initHomeSlider();
    // fInitCookieBar();

    // if (document.querySelectorAll('.js-cookie').length) { fInitCookieBar(); }
    if (document.querySelectorAll('.js-modal-open').length) { fInitModal(); }
    if (document.getElementById('SubscriptionForm')) { fTogglePaymentMethod(); }
    if (document.querySelector('.js-register')) { fRegisterForm(); }

    if (document.querySelector('.isotope-grid')) { initIsotope(); }

    if (document.querySelector('[data-module-video]')) {
        const videosBlocks = document.querySelectorAll('[data-module-video]');
        videosBlocks.forEach((block) => {
            videoblock(block);
        });
    };

    toggleMemberField();
    $("#footerCheckboxAlreadyMember").on("change", toggleMemberField);
// });

function toggleMemberField() {
    if ($("#footerCheckboxAlreadyMember").is(":checked")) {
        $("#memberNumber").closest(".form-group").show();
        $("#memberNumber").attr("required", "required");
    } else {
        $("#memberNumber").closest(".form-group").hide();
        $("#memberNumber").attr("required", null);
    }
}

function backToTop() {
if (($(window).height() + 100) < $(document).height()) {
        var oBackToTop = $('.js-scroll-to-top');
        if (oBackToTop.length) {
            oBackToTop.on('click', function () {
                $('html, body').animate({ scrollTop: 0 }, 'slow');
                return false;
            });
        }
    }
}

function stickyScrollBar() {
    const headerheight = 94;
    $(window).scroll(function () {
        if ($(this).scrollTop() > headerheight) {
            $(".js-scroll-sticky").addClass("sticky-bar");
            $(".navigation__dropdown").addClass('sticky-dropdown');

        } else {
            $(".js-scroll-sticky").removeClass("sticky-bar");
            $(".navigation__dropdown").removeClass('sticky-dropdown');
        }
    });
}
