import $ from 'jquery';
import 'slick-carousel';

export function initSlider() {
    if($(".owl-carousel").length) {
        $(".owl-carousel").owlCarousel({
            items: 1,
            animateOut: 'fadeOut',
            autoplay: true,
            autoplayHoverPause: true,
            loop: true,
            autoplayTimeout: 5000
        });
    }
}

export function initHomeSlider() {
    if ($(".js-home-slider").length) {
        $(".js-home-slider").slick({
            dots: true,
            infinite: true,
            speed: 1000,
            fade: true,
            autoplay: true,
            autoplaySpeed: 5000,
            cssEase: 'linear',
            arrows: false
        });
    }
}

